import { DateTime } from "luxon";
import { Plan, RenewalStrategy, RenewalType } from "../../models";

const buildInitialPlanSettings = (plan: Plan) => {
  const startDate = plan.renewalOptions?.startDate
    ? DateTime.fromISO(plan.renewalOptions.startDate)
    : null;
  const initialValues = {
    name: plan.name,
    planType: plan.type,
    ongoingPeriodInMonths: plan.renewalOptions?.periodInMonths ?? 12,
    oneOffPeriodInMonths:
      plan.renewalOptions?.type === RenewalType.NEVER
        ? -1
        : plan.renewalOptions?.periodInMonths ?? 6,
    renewalType: plan.renewalOptions?.type ?? RenewalType.INDIVIDUAL_START_DATE,
    renewalStrategy: plan.renewalOptions?.strategy ?? RenewalStrategy.RESET,
    startDate: startDate,
    controls: {
      purchases: {
        isEnabled: true
      },
      payments: {
        isEnabled: false
      }
    }
  };
  if (plan.renewalOptions?.periodInMonths) {
    initialValues.ongoingPeriodInMonths = plan.renewalOptions.periodInMonths;
    initialValues.oneOffPeriodInMonths = plan.renewalOptions.periodInMonths;
  }
  return initialValues;
};

export { buildInitialPlanSettings };

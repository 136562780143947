import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { Invoice, InvoiceItem, InvoiceItemType } from "../../models";
import { Table as DataTable } from "../shared/Table";
import { formatCurrency } from "../../utils/formatters";
import { ColumnDef } from "@tanstack/react-table";

const TableTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 18
})

const TotalDisplay = styled(Typography)({
  fontSize: 14,
  fontWeight: 600
})

type FeeSummaryItem = {
  item: string
  quantity: number
  amount: string
  total: string
}

type InvoicePlatformFeesProps = {
  invoice: Invoice;
};

type PlatformInvoiceItems = {
  monthlyUsers: InvoiceItem | undefined,
  issuedCards: InvoiceItem | undefined,
  renewedCards: InvoiceItem | undefined
}

const columns: ColumnDef<FeeSummaryItem>[] = [
  {
    accessorKey: "item",
    header: "Item",
    id: "Item"
  },
  {
    id: "quantity",
      header: () => (
      <Box display={"flex"} justifyContent="flex-end" style={{ width: "100%", textAlign: "right" }}>
        Quantity
      </Box>
    ),
    cell: ({ row }) => (
      <Box display={"flex"} justifyContent="flex-end">
        {row.original.quantity}
      </Box>
    ),
    enableSorting: false
  },
  {
    id: "amount",
    header: () => (
      <Box display={"flex"} justifyContent="flex-end" style={{ width: "100%", textAlign: "right" }}>
        Amount
      </Box>
    ),
    cell: ({ row }) => (
      <Box display={"flex"} justifyContent="flex-end">
        {row.original.amount}
      </Box>
    ),
    enableSorting: false,
  },
  {
    id: "total",
    header: () => (
      <Box display={"flex"} justifyContent="flex-end" style={{ width: "100%", textAlign: "right" }}>
        Total
      </Box>
    ),
    cell: ({ row }) => (
      <Box display={"flex"} justifyContent="flex-end">
        {row.original.total}
      </Box>
    ),
    enableSorting: false,
  }
]

const formatInvoiceItems = ({monthlyUsers, issuedCards, renewedCards} :  PlatformInvoiceItems) => {
  const feeSummaryData: FeeSummaryItem[] = [] 

  if(monthlyUsers){
    feeSummaryData.push({
      item: "Active User(s)",
      quantity: monthlyUsers.quantity,
      amount: formatCurrency(monthlyUsers.price),
      total: formatCurrency(monthlyUsers.total)
    })
  }

  if(issuedCards){
    feeSummaryData.push({
      item: "Issued Card(s)",
      quantity: issuedCards.quantity,
      amount: formatCurrency(issuedCards.price),
      total: formatCurrency(issuedCards.total)
    })
  }

  if(renewedCards){
    feeSummaryData.push({
      item: "Renewed Card(s)",
      quantity: renewedCards.quantity,
      amount: formatCurrency(renewedCards.price),
      total: formatCurrency(renewedCards.total)
    })
  }

  return feeSummaryData
}

export const InvoicePlatformFees = ({ invoice }: InvoicePlatformFeesProps) => {
  const monthlyUsers = invoice.items.find(x => x.type === InvoiceItemType.MONTHLY_USER_FEE);
  const issuedCards = invoice.items.find(x => x.type === InvoiceItemType.ISSUED_CARD_FEE);
  const renewedCards = invoice.items.find(x => x.type === InvoiceItemType.RENEW_CARD_FEE);

  const summaryData = formatInvoiceItems({ monthlyUsers, issuedCards, renewedCards })

  const grandTotal = (monthlyUsers?.total ?? 0) + (issuedCards?.total ?? 0) + (renewedCards?.total ?? 0)

  return (
    <Grid item style={{ paddingTop: "20px" }}>
      <Grid container flexDirection="column">
        <TableTitle>
          Platform Fees
        </TableTitle>
        <DataTable<FeeSummaryItem> 
          data={summaryData}
          totalItems={summaryData.length}
          columns={columns}
          displaySearch={false}
          displayFilters={false}
          rowSelection={false}
          showPagination={false}
        />
        <Stack 
          direction={"row"} 
          sx={{ borderTop: "2px solid #E4E7EC" }} 
          justifyContent={"space-between"}
          paddingY={2}
          paddingX={2}
          aria-label="platform fees display"
        >
          <TotalDisplay>
            Total
          </TotalDisplay>
          <TotalDisplay>
            {formatCurrency(grandTotal, 2)}
          </TotalDisplay>
        </Stack>
      </Grid>
    </Grid>
  );
};

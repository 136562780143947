import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Plan, PlanType, RenewalOptions, Tier } from "../../models";
import { mutatePlan } from "../../pages/plans/PlanDetails/mutatePlan";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { AllocationDetailsForm } from "./AllocationDetailsForm";

type EditAllocationProps = {
  allocation: Plan | undefined;
  handleClose: () => void;
};

// An "allocation" is an account/plan that is only used for sending money (payments).
export const EditAllocation = ({
  allocation,
  handleClose
}: EditAllocationProps) => {
  const { config } = useConfig();
  const { employerId, plans } = useData();
  const { getToken } = useKindeAuth();
  const [error, setError] = useState("");

  const mutateAllocation = useMutation(
    async (updatedAllocation: Plan) =>
      await mutatePlan(
        config,
        employerId as string,
        await getToken(),
        updatedAllocation
      ),
    {
      onSuccess: () => {
        toast.success("Allocation updated successfully");
        plans.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  // Only allow updating of a subset of the create plan/allocation values
  const handleAllocationSave = async (
    name: string,
    serviceSectorIds: string[],
    tiers: Tier[],
    planType: PlanType,
    renewalOptions: RenewalOptions,
    allowedTargetPlans: string[],
    reasonCategories: string[]
  ) => {
    if (!allocation) return; // Un-reachable

    const updatedAllocation = {
      ...allocation,
      name,
      tiers,
      controls: {
        ...allocation.controls,
        payments: {
          ...allocation.controls.payments,
          allowedTargetPlans,
          reasonCategories
        }
      }
    };
    await mutateAllocation.mutate(updatedAllocation);
  };

  return (
    <>
      <AllocationDetailsForm
        existingAllocation={allocation}
        isLoading={mutateAllocation.isLoading}
        onClose={handleClose}
        onSave={handleAllocationSave}
      />
      {error && (
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      )}
    </>
  );
};

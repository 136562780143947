import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Plus } from "react-feather";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { resolveRenewalPeriod } from "../../components/onboarding/TierInfoBox";
import { AddAllocation } from "../../components/plans/AddAllocation";
import { EditAllocation } from "../../components/plans/EditAllocation";
import { ColouredContainer } from "../../components/shared/ColoredContainer";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { PageHeader } from "../../components/shared/PageHeader";
import { SideDrawer } from "../../components/shared/SideDrawer";
import { TabGroup } from "../../components/shared/TabGroup";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";
import { MembershipType, Plan } from "../../models";
import { extraordinaryColors } from "../../utils/theme";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import {
  DeletePlanButton,
  PlanStatistics,
  StyledEditIcon
} from "../plans/Plans";

const Rewards = () => {
  const { setHeaderActions } = useContext(PageHeaderControlContext);
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { employer, plans, membership, accounts, employees } = useData();
  const [isAddingAllocation, setIsAddingAllocation] = useState<boolean>(false);
  const [editingAllocation, setEditingAllocation] = useState<
    Plan | undefined
  >();

  const isPilot = membership.data?.type === MembershipType.PILOT;
  const planTotalItems = plans?.data?.totalItems ?? 0;
  const canAddPlans = isPilot
    ? true
    : membership.data?.features?.maxPlanCount
    ? planTotalItems >= membership.data?.features?.maxPlanCount
    : false;

  const planTotalUsersMap: Record<string, number> = useMemo(() => {
    const planMap: Record<string, number> = {};
    accounts.data?.items
      .filter(
        (a) => !a.controls.purchases.isEnabled && a.controls.payments.isEnabled
      )
      .forEach((account) => {
        planMap[account.planId] ??= 0;
        planMap[account.planId] += 1;
      });

    return planMap;
  }, [accounts.data]);

  // An "allocation" is a plan that is only used for sending money (payments).
  const renderAllocation = (plan: Plan) => {
    const id = plan?.controls?.payments?.allowedTargetPlans?.[0];
    const receivingAccount = plans.data?.items.find((p) => p.id === id);
    const renewalPeriod = resolveRenewalPeriod(
      plan.renewalOptions.periodInMonths ?? 0
    );

    return (
      <Grid item xs={12} md={6} key={plan.id}>
        <ColouredContainer
          title={plan.name}
          color={extraordinaryColors.blue.main}
          toolbarItems={
            <>
              <DeletePlanButton
                totalUsers={planTotalUsersMap[plan.id] ?? 0}
                onClick={() => mutationDeleteAllocation.mutate(plan)}
              />
              <IconButton onClick={() => setEditingAllocation(plan)}>
                <StyledEditIcon />
              </IconButton>
            </>
          }
          subtitleItems={
            <PlanStatistics totalUsers={planTotalUsersMap[plan.id] ?? 0} />
          }
        >
          <Box sx={{ display: "flex", padding: 2 }}>
            <Box>
              <Typography fontSize={14} color="grey">
                Recipient Target Account
              </Typography>

              <Typography>{receivingAccount?.name}</Typography>
            </Box>

            <Typography ml="auto">
              ${plan?.tiers[0].amount.amount}
              {renewalPeriod !== undefined ? `/${renewalPeriod}` : null}
            </Typography>
          </Box>
        </ColouredContainer>
      </Grid>
    );
  };

  const mutationDeleteAllocation = useMutation(
    async (plan: Plan) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/plans/${plan.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        if (response.status === 409) {
          throw new Error(
            "There was a problem deleting this Allocation. To delete a Allocation, it must not be assigned to any employees."
          );
        }
        throw new Error("There was a problem deleting this Allocation.");
      }
    },
    {
      onSuccess: () => {
        toast.success("Allocation deleted successfully");
        employees.refetch();
        accounts.refetch();
        plans.refetch();
      },
      onError: (error: Error) => {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  );

  useEffect(() => {
    setHeaderActions([
      <Button
        variant="contained"
        onClick={() => setIsAddingAllocation(true)}
        startIcon={<Plus size={16} />}
        size="medium"
        disabled={canAddPlans}
      >
        New
      </Button>
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (plans.isLoading || plans.isIdle) {
    return <LoadingSpinner />;
  }

  if (plans.error || !plans.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  return (
    <>
      <PageHeader pageTitle="Rewards" />

      <TabGroup tabLabels={["Recognition"]}>
        <Grid container spacing={2} my={1}>
          {plans.data.items
            .filter(
              (p) =>
                !p.controls.purchases.isEnabled && p.controls.payments.isEnabled
            )
            .map(renderAllocation)}
        </Grid>
      </TabGroup>

      <SideDrawer
        title="Add Allocation"
        open={isAddingAllocation}
        setIsOpen={setIsAddingAllocation}
      >
        <AddAllocation handleClose={() => setIsAddingAllocation(false)} />
      </SideDrawer>

      <SideDrawer
        title="Edit Allocation"
        open={!!editingAllocation}
        setIsOpen={setEditingAllocation}
      >
        <EditAllocation
          allocation={editingAllocation}
          handleClose={() => setEditingAllocation(undefined)}
        />
      </SideDrawer>
    </>
  );
};

export { Rewards };

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Tooltip } from "@mui/material";
import { extraordinaryColors } from "../../utils/theme";

export const RewardAllocationLogoTooltip = () => {
  return (
    <Tooltip title="Reward allocation account">
      <EmojiEventsIcon sx={{ color: extraordinaryColors.green.light, ml: 1 }} />
    </Tooltip>
  );
};

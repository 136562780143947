import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Input,
  LinearProgress,
  Slider,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { Invoice, InvoiceType } from "../../models";
import { extraordinaryColors } from "../../utils/theme";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { LoadingSpinner } from "../shared/LoadingSpinner";
import { PaymentDialog } from "./PaymentDialog";

const ColoredLinearProgress = styled(LinearProgress)({
  height: 18,
  borderRadius: 12,

  "&.MuiLinearProgress-root": {
    backgroundColor: extraordinaryColors.orange.light
  },

  "& .MuiLinearProgress-bar": {
    backgroundColor: extraordinaryColors.orange.main
  }
})

const ColoredSlider = styled(Slider)({
  maxWidth: 350,
  marginLeft: "32px",
  marginRight: "32px",
  
  "& .MuiSlider-thumb": {
    color: extraordinaryColors.orange.main
  },

  "& .MuiSlider-track": {
    color: extraordinaryColors.orange.main
  },

  "& .MuiSlider-rail": {
    color: extraordinaryColors.orange.light
  }
})

const PaymentBoxContainer = styled(Box)({
  backgroundColor: extraordinaryColors.orange.light,
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingBottom: "16px",
  borderRadius: "10px",
})

const TopUpAmountLabel = styled(Typography)({
  color: "#454545",
  fontSize: 14,
  lineHeight: 3
})

const TopUpAmountAdornment = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
})


const TopUpAmountDisplay = styled(Input)({
  fontSize: "24px",
  fontWeight: 700,
  width: 100,
})

const FloatMessageDisplay = styled(Typography)({ fontSize: 12, color: "#737373" })

export const FloatPaymentBox = () => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { float, employer, invoices } = useData();

  const [amount, setAmount] = useState<number>(0);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);

  const createFloatInvoice = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data!.id}/invoices`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            type: InvoiceType.FLOAT,
            amount: amount
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem processing the payment");
      }

      const invoice = await response.json();
      setInvoice(invoice);
    },
    onSuccess: () => {
      setPaymentDialogOpen(true);
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const handleAddPayment = async () => {
    setError(undefined);
    if (isNaN(amount) || amount <= 10) { // $10 limit on top ups
      setError("Top up amount needs to be at least $10");
      return;
    }
    setProcessingPayment(true);
    await createFloatInvoice.mutateAsync();
  };

  const onSuccessfulPayment = async () => {
    if (invoice == null) {
      return;
    }

    try {
      await invoices.refetch();
      await float.refetch();
      setPaymentDialogOpen(false);
      setProcessingPayment(false);
    } catch (e) {
      setError(
        "There was an error while processing your payment. Please contact support!"
      );
    }
  };

  if (float.isIdle || float.isLoading || employer.isLoading)
    return <LoadingSpinner />;

  if (!float.data) return <Typography>Error Loading</Typography>;

  return (
    <>
      {invoice != null && (
        <PaymentDialog
          amount={invoice.amount}
          reference={employer.data!.name}
          title={`Funding top up`}
          open={paymentDialogOpen}
          invoiceId={invoice.id}
          countryId={employer.data?.country.id ?? ""}
          onCancel={() => {
            setPaymentDialogOpen(false);
            setProcessingPayment(false);
          }}
          onSuccess={onSuccessfulPayment}
        />
      )}
        <PaymentBoxContainer>
          <FloatMessageDisplay py={2}>
            You have used{" "}
            {(float.data.minimumAmount.amount -
              float.data.availableAmount.amount) /
              float.data.minimumAmount.amount <
            0
              ? 0
              : Math.min((
                  ((float.data.minimumAmount.amount -
                    float.data.availableAmount.amount) /
                    float.data.minimumAmount.amount) *
                  100
                ), 100).toFixed(0)}
            % of your Available Funding. Paying any outstanding
            invoices will reset this amount or you
            can top up your Available Funding.
          </FloatMessageDisplay>
          <ColoredLinearProgress
            variant="determinate"
            value={
              Math.min(((float.data.minimumAmount.amount -
                float.data.availableAmount.amount) /
                float.data.minimumAmount.amount) *
              100, 100)
            }
            sx={{ height: 18 }}
          />
          <Box
            display="flex"
            alignItems="center"
            sx={{ m: 1 }}
            gap={2}
          >
            <Box flexDirection={"column"}>
              <TopUpAmountLabel>Top Up Amount</TopUpAmountLabel>
              <TopUpAmountDisplay
                value={amount}
                startAdornment={<TopUpAmountAdornment>$</TopUpAmountAdornment>}
                onChange={(e) => {
                  const parsedValue = Number(e.currentTarget.value);
                  if(Number.isNaN(parsedValue)) return;
                  setAmount(+e.currentTarget.value)
                }}
              />
            </Box>
            <ColoredSlider
              value={amount}
              onChange={(e, newValue) =>
                setAmount(newValue as number)
              }
              marks={[{ value: 0, label: "$0" }, { value: 25000, label: "$25000"} ]}
              min={0}
              max={25000}
            />

            <LoadingButton
              onClick={handleAddPayment}
              variant="contained"
              color="primary"
              loading={
                processingPayment ||
                createFloatInvoice.isLoading ||
                employer.isLoading ||
                employer.data === undefined
              }
              autoFocus
              sx={{marginLeft: "auto"}}
            >
              Add Funds
            </LoadingButton>
          </Box>
          {error && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ m: 0 }}
            >
              <Typography color={"error"}>{error}</Typography>
            </Box>
          )}
        </PaymentBoxContainer>
    </>
  );
};

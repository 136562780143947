import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { EditMode, ServiceSector } from "../../models";
import { ServiceSectorStatus } from "../../models/serviceSectors/serviceSectorStatus";

type PlanRestrictionsProps = {
  initialServiceSectorIds: string[];
  serviceSectors: ServiceSector[];
  editMode: EditMode;
  isLoading: boolean;
  onBack: () => void;
  saveServiceSectorIds: (serviceSectorIds: string[]) => void;
  onFinish: (serviceSectorIds: string[]) => void;
};

const RestrictedOption = "restricted";
const OpenOption = "open";

export const PlanRestrictionsForm = ({
  initialServiceSectorIds,
  serviceSectors,
  editMode,
  isLoading,
  onBack,
  saveServiceSectorIds,
  onFinish
}: PlanRestrictionsProps) => {
  const activeServiceSectors = serviceSectors.filter(
    (ss) => ss.status === ServiceSectorStatus.ACTIVE
  );
  const getOpenServiceSector = () => {
    return activeServiceSectors.find(
      (ss) =>
        ss.restrictions.mccCodes.allow.length === 0 &&
        ss.restrictions.mccCodes.block.length === 0 &&
        ss.restrictions.providerNames.allow.length === 0 &&
        ss.restrictions.providerNames.block.length === 0
    );
  };

  const foundOpenServiceSector = getOpenServiceSector();
  const nonOpenServiceSectors = foundOpenServiceSector
    ? activeServiceSectors.filter((x) => x.id !== foundOpenServiceSector.id)
    : activeServiceSectors;
  const [openServiceSector, setOpenServiceSector] =
    useState<ServiceSector | null>(foundOpenServiceSector ?? null);
  const [serviceSectorsWithoutOpen, setServiceSectorsWithoutOpen] = useState<
    ServiceSector[]
  >(
    nonOpenServiceSectors
      .map((s) => ({
        ...s,
        category: s.category ? s.category : "Uncategorised"
      }))
      .sort((a, b) => {
        if (a.category === "Uncategorised" && b.category === "Uncategorised")
          return 0;
        if (a.category === "Uncategorised") return 1;
        if (b.category === "Uncategorised") return -1;
        return a.category.localeCompare(b.category);
      })
  );
  
  const [selectedOption, setSelectedOption] = useState(RestrictedOption);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = (event.target as HTMLInputElement).value;
    setSelectedOption(newOption);
  };

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      serviceSectorIds: initialServiceSectorIds
    },
    onSubmit: async (values) => {
      if (selectedOption === RestrictedOption) {
        saveServiceSectorIds(values.serviceSectorIds);
        onFinish(values.serviceSectorIds);
      } else {
        saveServiceSectorIds([openServiceSector!.id]);
        onFinish([openServiceSector!.id]);
      }
    },
    enableReinitialize: true
  });

  useEffect(() => {
    if (
      formik.values.serviceSectorIds.find((x) => openServiceSector!.id === x)
    ) {
      setSelectedOption(OpenOption);
    }
  }, [formik.values.serviceSectorIds]);

  const displayCategorySelection = () => {
    return (
      <>
        <Typography variant="subtitle1">Categories</Typography>
        <Box>
          <Autocomplete
            disabled={selectedOption !== RestrictedOption}
            multiple
            options={serviceSectorsWithoutOpen}
            groupBy={(option) => option.category}
            getOptionLabel={(option) => option.name}
            value={serviceSectorsWithoutOpen.filter((sector) =>
              formik.values.serviceSectorIds?.includes(sector.id)
            )}
            onChange={(_, newValue) => {
              let newIds = newValue.map((sector) => sector.id);
              formik.setFieldValue("serviceSectorIds", newIds);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    overflowY: "auto",
                    minHeight: 60,
                    height: "auto",
                    paddingLeft: 10,
                    paddingRight: 10,
                    backgroundColor: "white"
                  }
                }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  style: {
                    overflowY: "auto",
                    minHeight: 60,
                    paddingLeft: 10,
                    paddingRight: 10
                  }
                }}
                placeholder="Categories"
              />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    paddingLeft: 1,
                    paddingTop: 1,
                    fontSize: 14
                  }}
                >
                  {params.group}
                </Typography>
                <ul style={{ paddingLeft: 20, margin: 0 }}>
                  {params.children}
                </ul>
              </li>
            )}
          />
          {formik.errors.serviceSectorIds && (
            <Typography color="error" sx={{ mt: 2 }}>
              {formik.errors.serviceSectorIds}
            </Typography>
          )}
        </Box>
      </>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Grid item xs={12} md={12} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          {openServiceSector === null && displayCategorySelection()}
          {openServiceSector !== null && (
            <RadioGroup value={selectedOption} onChange={handleRadioChange}>
              <FormControlLabel
                value={RestrictedOption}
                control={<Radio />}
                label="Account should only work within these categories"
              />
              {selectedOption === RestrictedOption &&
                displayCategorySelection()}
              <FormControlLabel
                value={OpenOption}
                control={<Radio />}
                label="This account should work everywhere"
              />
            </RadioGroup>
          )}
          {editMode === EditMode.EDIT && (
            <Typography sx={{ mt: 2, mb: 4 }} variant="body2">
              Changes to categories will be effective immediately
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} mt={4}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
              fullWidth
            >
              {editMode === EditMode.CREATE ? "Create" : "Save"}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

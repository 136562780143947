import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Minus, Plus } from "react-feather";
import * as yup from "yup";
import {
  EditMode,
  Plan,
  PlanType,
  RenewalOptions,
  RenewalStrategy,
  RenewalType,
  Tier
} from "../../models";
import { useData } from "../../utils/useData";

type AllocationDetailsProps = {
  existingAllocation?: Plan | null;
  isLoading: boolean;
  onClose: () => void;
  onSave: (
    name: string,
    serviceSectorIds: string[],
    tiers: Tier[],
    planType: PlanType,
    renewalOptions: RenewalOptions,
    allowedTargetPlans: string[],
    reasonCategories: string[]
  ) => void;
};

export const AllocationDetailsForm = ({
  isLoading,
  onClose,
  onSave,
  existingAllocation
}: AllocationDetailsProps) => {
  const { employer, plans } = useData();
  const editMode = existingAllocation == null ? EditMode.CREATE : EditMode.EDIT;

  const [addingReasonCategoryKey, setAddingReasonCategoryKey] =
    useState<number>(0);
  const [addingReasonCategory, setAddingReasonCategory] = useState<
    string | undefined
  >();

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      name: existingAllocation?.name ?? "",
      planType: existingAllocation?.type ?? PlanType.ONGOING,
      tiers: existingAllocation?.tiers ?? [
        {
          id: undefined,
          name: "Base Tier",
          amount: {
            amount: 50,
            currencyCode: employer.data?.country.currencyCode
          }
        }
      ],
      serviceSectorIds: [],
      renewalOptions: existingAllocation?.renewalOptions ?? {
        strategy: RenewalStrategy.RESET,
        type: RenewalType.INDIVIDUAL_START_DATE,
        periodInMonths: 1
      },
      controls: {
        payments: {
          isEnabled: true,
          allowedTargetPlans:
            existingAllocation?.controls?.payments?.allowedTargetPlans ?? [],
          reasonCategories:
            existingAllocation?.controls?.payments?.reasonCategories ?? []
        },
        purchases: {
          isEnabled: false
        }
      }
    },
    validationSchema: yup.object().shape({
      name: yup.string().max(50).required(),
      controls: yup.object().shape({
        purchases: yup.object().shape({
          isEnabled: yup
            .boolean()
            .oneOf([false], "Purchases must be disabled")
            .required()
        }),
        payments: yup.object().shape({
          isEnabled: yup
            .boolean()
            .oneOf([true], "Payments must be enabled")
            .required(),
          reasonCategories: yup
            .array()
            .of(yup.string().min(1).max(100))
            .min(1, "At least one reason category is required")
            .required()
        })
      })
    }),
    onSubmit: async (values) => {
      await onSave(
        values.name,
        values.serviceSectorIds,
        values.tiers,
        values.planType,
        values.renewalOptions,
        values.controls.payments.allowedTargetPlans,
        values.controls.payments.reasonCategories
      );
    },
    enableReinitialize: true
  });

  return (
    <Box sx={{ width: "100%" }} padding={2}>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12} md={12}>
          <Box my={2}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Allocation Name</FormLabel>
              <TextField
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
                required
                fullWidth
                size="small"
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
          </Box>

          <Box my={2}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Amount</FormLabel>
              <TextField
                type="number"
                fullWidth
                value={formik.values.tiers[0].amount.amount}
                onChange={(e) =>
                  formik.setFieldValue("tiers", [
                    {
                      ...formik.values.tiers[0],
                      amount: {
                        amount: e.target.value,
                        currencyCode: formik.values.tiers[0].amount.currencyCode
                      }
                    }
                  ])
                }
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Box>

          <Box
            my={2}
            display="flex"
            flexDirection={"column"}
            alignItems="start"
            justifyContent="space-between"
          >
            <FormLabel component="legend">Frequency</FormLabel>
            <FormControl component="fieldset" fullWidth>
              <Select
                name="renewalOptions.periodInMonths"
                value={formik.values.renewalOptions.periodInMonths}
                onChange={formik.handleChange}
                disabled={editMode === EditMode.EDIT}
              >
                <MenuItem value={1}>Monthly</MenuItem>
                <MenuItem value={6}>Bi-Annually</MenuItem>
                <MenuItem value={12}>Annually</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            my={2}
            display="flex"
            flexDirection={"column"}
            alignItems="start"
            justifyContent="space-between"
          >
            <FormLabel component="legend">Recipient target account</FormLabel>
            <FormControl component="fieldset" fullWidth>
              <Select
                name="allowedTargetPlans"
                required
                value={formik.values.controls.payments.allowedTargetPlans[0]}
                onChange={(e) => {
                  formik.setFieldValue(
                    "controls.payments.allowedTargetPlans",
                    [e.target.value]
                  );
                }}
              >
                {plans.data?.items
                  .filter((p) => p.controls.purchases.isEnabled)
                  .map((p) => {
                    return <MenuItem value={p.id}>{p.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Box>

          <Box my={2}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Reasons</FormLabel>
              {formik.values.controls.payments.reasonCategories.map(
                (rc, index) => {
                  return (
                    <Box display="flex" alignItems="center">
                      <Typography mt={index === 0 ? 2 : 1} mb={1}>
                        {rc}
                      </Typography>

                      <Minus
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          formik.setFieldValue(
                            "controls.payments.reasonCategories",
                            formik.values.controls.payments.reasonCategories.filter(
                              (_, i) => i !== index
                            )
                          );
                        }}
                      />
                    </Box>
                  );
                }
              )}

              <Box display="flex" alignItems="center" marginTop={1} gap={1}>
                <TextField
                  key={addingReasonCategoryKey}
                  value={addingReasonCategory}
                  onChange={(e) => setAddingReasonCategory(e.target.value)}
                  name="controls.payments.reasonCategories"
                  fullWidth
                  size="small"
                  error={
                    formik.touched.controls?.payments?.reasonCategories &&
                    Boolean(formik.errors.controls?.payments?.reasonCategories)
                  }
                />
                <Plus
                  onClick={() => {
                    if (!addingReasonCategory) return;

                    let categories =
                      formik.values.controls.payments.reasonCategories;
                    categories.push(addingReasonCategory);

                    formik.setFieldValue(
                      "controls.payments.reasonCategories",
                      categories
                    );
                    setAddingReasonCategory(undefined);
                    setAddingReasonCategoryKey(addingReasonCategoryKey + 1);
                  }}
                />
              </Box>
              {formik.touched.controls?.payments?.reasonCategories &&
                formik.errors.controls?.payments?.reasonCategories && (
                  <Typography color="error">
                    {formik.errors.controls?.payments?.reasonCategories}
                  </Typography>
                )}
            </FormControl>
          </Box>

          <Grid item xs={12} mt={4}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { PlanType, RenewalOptions, Tier } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { CreatePlanInput } from "./CreatePlanInput";
import { PlanDetailsForm } from "./PlanDetailsForm";

type EditPlanProps = {
  isPilot: boolean;
  handleClose: () => void;
};

export const AddPlan = ({ isPilot, handleClose }: EditPlanProps) => {
  const { config } = useConfig();
  const { employerId, plans, serviceSectors, employees, accounts } = useData();
  const { getToken } = useKindeAuth();
  const [error, setError] = useState("");

  const mutationCreatePlan = useMutation(
    async (values: CreatePlanInput) => {
      if (!values.serviceSectorIds.length) {
        throw new Error("Plan must have at least 1 category selected");
      }

      if (!values.tiers.length) {
        throw new Error("Plan must have at least 1 tier");
      }

      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/plans`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: values.name,
            serviceSectorIds: values.serviceSectorIds,
            tiers: values.tiers.map((tier) => {
              return {
                name: tier.name,
                amount: tier.amount.amount
              };
            }),
            type: values.planType,
            renewalOptions: values.renewalOptions,
            controls: {
              purchases: values.purchases,
              payments: values.payments
            }
          })
        }
      );

      if (!response.ok)
        throw new Error("There was a problem updating this plan");
    },
    {
      onSuccess: () => {
        toast.success("Plan updated successfully");
        plans.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const handlePlanSave = async (
    name: string,
    serviceSectorIds: string[],
    tiers: Tier[],
    planType: PlanType,
    renewalOptions: RenewalOptions
  ) => {
    await mutationCreatePlan.mutate({
      name,
      serviceSectorIds,
      tiers,
      planType,
      renewalOptions,
      payments: {
        isEnabled: false
      },
      purchases: {
        isEnabled: true
      }
    });
  };

  return (
    <>
      <PlanDetailsForm
        isPilot={isPilot}
        employees={employees.data?.items ?? []}
        accounts={accounts.data?.items ?? []}
        serviceSectors={serviceSectors.data?.items ?? []}
        isLoading={mutationCreatePlan.isLoading}
        onClose={handleClose}
        onSave={handlePlanSave}
      />
      {error && (
        <Grid item xs={12}>
          <Typography color="error">{error}</Typography>
        </Grid>
      )}
    </>
  );
};

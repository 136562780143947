import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Plus, Users } from "react-feather";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import { ReactComponent as TrashCan } from "../../assets/trash.svg";
import { AddPlan } from "../../components/plans/AddPlan";
import { AccountSummary } from "../../components/shared/AccountSummary";
import { ColouredContainer } from "../../components/shared/ColoredContainer";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { PageHeader } from "../../components/shared/PageHeader";
import { SideDrawer } from "../../components/shared/SideDrawer";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";
import { MembershipType, Plan } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

export const StyledEditIcon = styled(Edit)({ cursor: "pointer" });
const StyledTrash = styled(TrashCan)({ marginLeft: "auto", cursor: "pointer" });

const UsersIcon = styled(Users)({ color: "white", marginRight: 8 });

export const PlanStatistics = ({ totalUsers }: { totalUsers: Number }) => {
  const totalUserDisplay = `${totalUsers} Active Users`;

  return (
    <Box display={"flex"} flexDirection={"row"} paddingY={2}>
      <UsersIcon />
      <Typography marginRight={2}>{totalUserDisplay}</Typography>
    </Box>
  );
};

export const DeletePlanButton = ({
  totalUsers,
  onClick
}: {
  totalUsers: number;
  onClick: Function;
}) => {
  return (
    <Tooltip
      title={
        totalUsers === 0
          ? "Delete"
          : "Cannot delete plan - Employees are assigned to it."
      }
    >
      <div>
        <IconButton disabled={totalUsers !== 0} onClick={onClick as any}>
          <StyledTrash />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export const Plans = () => {
  const { plans, membership, serviceSectors, accounts, employees, employerId } =
    useData();

  const { config } = useConfig();
  const navigate = useNavigate();
  const { setHeaderActions } = useContext(PageHeaderControlContext);
  const [addingPlan, setAddingPlan] = useState<boolean>(false);
  const { getToken } = useKindeAuth();

  const planTotalUsersMap: Record<string, number> = useMemo(() => {
    const planMap: Record<string, number> = {};
    accounts.data?.items.forEach((account) => {
      planMap[account.planId] ??= 0;
      planMap[account.planId] += 1;
    });

    return planMap;
  }, [accounts.data]);

  const isPilot = membership.data?.type === MembershipType.PILOT;
  const planTotalItems = plans?.data?.totalItems ?? 0;
  const canAddPlans = isPilot
    ? true
    : membership.data?.features?.maxPlanCount
    ? planTotalItems >= membership.data?.features?.maxPlanCount
    : false;

  useEffect(() => {
    setHeaderActions([
      <Button
        variant="contained"
        onClick={() => setAddingPlan(true)}
        startIcon={<Plus size={16} />}
        size="medium"
        disabled={canAddPlans}
      >
        New
      </Button>
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutationDeletePlan = useMutation(
    async (plan: Plan) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/plans/${plan.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok) {
        if (response.status === 409) {
          throw new Error(
            "There was a problem deleting this Plan. To delete a plan, it must not be assigned to any employees."
          );
        }
        throw new Error("There was a problem deleting this Plan.");
      }
    },
    {
      onSuccess: () => {
        toast.success("Plan deleted successfully");
        employees.refetch();
        accounts.refetch();
        plans.refetch();
      },
      onError: (error: Error) => {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  );

  if (
    plans.isLoading ||
    plans.isIdle ||
    serviceSectors.isLoading ||
    serviceSectors.isIdle
  ) {
    return <LoadingSpinner />;
  }

  if (plans.error || !plans.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const renderPlan = (plan: Plan) => {
    return (
      <Grid item xs={12} md={6} key={plan.id}>
        <ColouredContainer
          title={plan.name}
          color={"#E6A236"}
          toolbarItems={
            <>
              <DeletePlanButton
                totalUsers={planTotalUsersMap[plan.id] ?? 0}
                onClick={() => mutationDeletePlan.mutate(plan)}
              />
              <IconButton
                onClick={() => navigate(`/accounts/${plan.id}/details`)}
              >
                <StyledEditIcon />
              </IconButton>
            </>
          }
          subtitleItems={
            <PlanStatistics totalUsers={planTotalUsersMap[plan.id] ?? 0} />
          }
        >
          <Box sx={{ padding: 2 }}>
            <AccountSummary
              variant="fullColor"
              planInfo={{
                ...plan,
                planType: plan.type,
                serviceSectorIds:
                  plan.controls.serviceSectors?.map((ssref) => ssref.id) ?? []
              }}
              serviceSectors={serviceSectors.data?.items}
            />
          </Box>
        </ColouredContainer>
      </Grid>
    );
  };

  return (
    <>
      <PageHeader pageTitle="Accounts" />
      <Grid container spacing={2}>
        {plans.data.items
          .filter(
            (p) =>
              p.controls.purchases.isEnabled && !p.controls.payments.isEnabled
          )
          .map(renderPlan)}
      </Grid>

      <SideDrawer
        title="Add Account"
        open={addingPlan}
        setIsOpen={setAddingPlan}
      >
        <AddPlan isPilot={isPilot} handleClose={() => setAddingPlan(false)} />
      </SideDrawer>
    </>
  );
};
